import MouseTrailer from "./Components/MouseTrailer/MouseTrailer";
import PageRedirectHandler from "./Pages/PageRedirectHandler";
import { BrowserView } from "react-device-detect";
import { Analytics } from "@vercel/analytics/react";
import "./App.scss";

const App = () => {
  return (
    <>
      <iframe
        src="https://comprehensive-strategy-744507.framer.app/"
        style={{
          position: "fixed",
          top: 0,
          left: 0,
          width: "100%",
          height: "100%",
          overflow: "hidden",
          border: "none",
        }}
      />
      {/* <BrowserView>
        <MouseTrailer />
      </BrowserView>
      <PageRedirectHandler /> */}
      <Analytics />
    </>
  );
};

export default App;
