import NorthEastIcon from "@mui/icons-material/NorthEast";
import PlayArrowIcon from "@mui/icons-material/PlayArrow";
import RadioButtonUncheckedIcon from "@mui/icons-material/RadioButtonUnchecked";
import ImageIcon from "@mui/icons-material/Image";
const fontSize = "small";
const MouseTrailerIcons = {
  link: <NorthEastIcon fontSize={fontSize} />,
  video: <PlayArrowIcon fontSize={fontSize} />,
  button: <RadioButtonUncheckedIcon fontSize={fontSize} />,
  image: <ImageIcon fontSize={fontSize} />,
};
export default MouseTrailerIcons;
